<template>
<progress-bar :value="value" :max="max" />
</template>

<script>
import ProgressBar from '@/components/ProgressBar'

export default {
  name: 'StatusProgressBar',
  components: {
    ProgressBar
  },
  props: {
    status: {
      required: true
    },
    statusSteps: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      timer: null,
      startDate: null,
      endDate: null
    }
  },
  computed: {
    step () {
      if (!this.status) return 0
      const step = this.statusSteps.findIndex(step => step.status === this.status)
      return step === -1 ? 0 : step
    },
    value () {
      return this.statusSteps.reduce((acc, step, index) => {
        const { max } = step
        if (index < this.step) {
          return acc + max
        } else if (index === this.step) {
          return acc + Math.min(max, this.getDelta())
        }
        return acc
      }, 0)
    },
    max () {
      return this.statusSteps.reduce((acc, step) => acc + step.max, 0)
    }
  },
  methods: {
    getDelta () {
      return Math.round((this.endDate - this.startDate) / 1000)
    },
    updateStep () {
      this.endDate = new Date()
      if (this.status === 'ProcessingCompleted') {
        const lastMax = this.statusSteps[this.statusSteps.length - 1]
        const delta = this.getDelta()
        if (lastMax <= delta) {
          clearTimeout(this.timer)
        }
      }
    }
  },
  watch: {
    status () {
      this.startDate = new Date(Date.now() - 1000)
    }
  },
  created () {
    this.startDate = new Date()
    this.updateStep()
    this.timer = setInterval(this.updateStep, 1000)
  },
  beforeDestroy () {
    clearInterval(this.timer)
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/swoop/variables';

.progress-bar {
  height: 8px;
  border-radius: 100px;
  border-width: 1px;
  border-style: solid;
  border-color: $color-white;
  box-shadow: $default-box-shadow;
}
/deep/ {
  .progress-bar-value {
    border-radius: 100px;
    transition-duration: 950ms;
    transition-timing-function: linear;
  }
}
</style>
