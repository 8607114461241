<template src="./banking.html"></template>
<style lang="scss" src="./banking.scss" scoped></style>

<script>
import { mapGetters, mapActions } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'
import { bankGetters, bankMandateListRoutine } from '@/store/modules/bank/routines'
import BankLogo from '@/components/BankLogo.vue'
import AlertIntegrations from '@/components/AlertIntegrations'
import StatusProgressBar from '@/components/StatusProgressBar'
import { getBankComparisonStatus, getBankComparisonList } from '@/api/bankComparison'
import { forwardAIIntegrationRoutine, forwardAIGetters } from '@/store/modules/forwardAI/routines'

export default {
  name: 'SavingsBanking',
  components: {
    AlertIntegrations,
    StatusProgressBar,
    BankLogo
  },
  data() {
    return {
      headers: [
        {
          text: 'Annual Savings',
          className: 'small-column',
          tooltip: 'This is an indication of potential annual savings based on your past 12 month transactions. Please note that terms and conditions may apply for each offer.'
        },
        {
          text: 'Overall service quality',
          className: 'small-column',
          tooltip:
            'As part of a regulatory requirement by the Competitions and Markets Authority (CMA), an independent survey was conducted to ask customers of the 14 largest business current account providers if they would recommend their provider to other small and medium-sized enterprises (SMEs).'
        },
        {
          text: 'Account highlights',
          className: 'small-column',
          tooltip: 'These are the features that the bank has decided to showcase to you about their business current account.'
        }
      ],
      bankComparisonStatusSteps: [
        {
          status: null,
          max: 4
        },
        {
          status: 'NotStarted',
          max: 2
        },
        {
          status: 'Processing',
          max: 1 * 60
        },
        {
          status: 'Completed',
          max: 4
        }
      ],
      nonAlphaStageBankList: ['Santander', 'Rbs', 'Natwest', 'Starling', 'HSBC', 'Barclays'],
      bankComparisonStatus: null, // ['NotStarted', 'Processing', 'Completed', 'Failed']
      bankComparison: null,
      isLoading: true,
      isIntegrationAlertVisible: false,
      loadBankComparisonTimerId: null
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID,
      bankMandateId: bankGetters.BANK_MANDATE_ID,
      companyState: companyGetters.COMPANY_STATE,
      bankMandateList: bankGetters.BANK_MANDATE_LIST,
      forwardAIIntegration: forwardAIGetters.FORWARDAI_INTEGRATION
    })
  },
  methods: {
    ...mapActions({
      getBankMandateList: bankMandateListRoutine.TRIGGER,
      getForwardAIIntegration: forwardAIIntegrationRoutine.TRIGGER
    }),
    closeAlert() {
      this.isIntegrationAlertVisible = false
    },
    loadBankComparisonStatus() {
      return getBankComparisonStatus(this.bankMandateId)
        .then(res => {
          this.bankComparisonStatus = res.data.status
        })
        .catch(e => {
          this.bankComparisonStatus = null
        })
    },
    loadBankComparison() {
      return getBankComparisonList(this.bankMandateId)
        .then(res => {
          this.bankComparison = res.data
          return res
        })
        .catch(e => {
          this.bankComparison = []
        })
    },
    async loadBankComparisonTillCompleted() {
      await this.loadBankComparisonStatus()
      if (this.bankComparisonStatus) {
        if (this.bankComparisonStatus === 'Completed') {
          return this.loadBankComparison()
        } else if (this.bankComparisonStatus === 'Failed') {
          return
        }
        return new Promise(resolve => {
          this.loadBankComparisonTimerId = setTimeout(() => {
            resolve(this.loadBankComparisonTillCompleted())
          }, 400)
        })
      }
    },
    checkAlertVisibility() {
      const forwardAIIntegrationSuccessState = ['connected', 'ready']
      const forwardAIStatus = this.forwardAIIntegration ? this.forwardAIIntegration.status : this.forwardAIIntegration
      this.isIntegrationAlertVisible = !this.bankMandateList.length || !forwardAIIntegrationSuccessState.includes(forwardAIStatus)
    },
    async init() {
      await Promise.all([this.getBankMandateList(this.companyId).catch(), this.getForwardAIIntegration(this.companyId).catch()])
      if (this.bankMandateId) {
        await this.loadBankComparisonTillCompleted()
      } else {
        return this.$router.push({ name: 'savings-static-banking' })
      }
      this.checkAlertVisibility()
      // For better UX
      setTimeout(() => {
        this.isLoading = false
      }, 2500)
    }
  },
  created() {
    this.init()
  },
  beforeDestroy() {
    clearTimeout(this.loadBankComparisonTimerId)
  }
}
</script>
